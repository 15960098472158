import { template as template_73990c93321a42b8916071e30143077a } from "@ember/template-compiler";
const FKControlMenuContainer = template_73990c93321a42b8916071e30143077a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
