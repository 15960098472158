import { template as template_628755b932b24593a2f4f1c6301eb468 } from "@ember/template-compiler";
const FKText = template_628755b932b24593a2f4f1c6301eb468(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
